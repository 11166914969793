<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="90%" >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="green white--text" v-bind="attrs" v-on="on" >
          edit
        </v-btn>
      </template> -->
      <v-form @submit="updateListItem()" v-model="isValid" v-on:submit.prevent>
        <v-card>
          <!-- <v-card-title class="headline">
            ..
          </v-card-title> -->
          <v-card-text>
            <!-- <RichEditor :editContent=initialContent /> -->
            <vue-editor ref="quillEditor" v-model="editContent" :editorOptions="editorOption" ></vue-editor>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-spacer />
            <v-btn type="submit" :disabled="!isValid" color="green white--text" >
              SAVE
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="handleCancel()" :disabled="!isValid" color="green white--text" >
              CANCEL
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
  import { VueEditor, Quill } from "vue2-editor";
  import api from '/src/utils/api';

  const NewListModal = {
    components: {
      // RichEditor: () => import("/src/components/RichEditor.vue"),
      VueEditor,
    },
    data() {
      return {
        dialog: true,
        isValid:false,
        quillInstance: null,
        editContent: "",
        editorOption: {
          theme: 'snow',
          modules: {
            toolbar: {
                container: [
                    // ["bold", "italic", "underline"],
                    // [{ list: "ordered" }, { list: "bullet" }],
                    // ["image", "code-block"],
                    // ["bold", "italic", "underline", "strike"], // toggled buttons
                    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                    [{ font: [] }],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    ["blockquote", "code-block"],

                    // [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: "ordered" }, { list: "bullet" }],
                    // [{ script: "sub" }, { script: "super" }], // superscript/subscript
                    // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                    [{ direction: "rtl" }], // text direction

                    // [{ header: [1, 2, 3, 4, 5, 6, false] }],

                    [{ align: [] }],
                    ["link", "image", "video"],
                    // ["clean"],
                ],
                handlers: {
                    image: () => {
                        var range = this.quillInstance.getSelection();
                        var value = prompt('please copy paste the image url here.');
                        if(value){
                            this.quillInstance.insertEmbed(range.index, 'image', value, Quill.sources.USER);
                        }
                    }
                }
            },
            // imageResize: {},
            // imageResize: {modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]},
          },
        },
      }
    },
    mounted() {
      this.quillInstance = this.$refs.quillEditor.quill;
      this.editContent = this.initialContent;
      // Quill.register("modules/imageResize", ImageResize);
    },
    methods: {
      updateListItem() {
        console.log("RichEditorModal::updating");
        this.dialog = false;
        this.handleSuccess(this.editContent);
      },
    },
    props: ['handleSuccess','handleCancel','initialContent'],
  }
  export default NewListModal;
</script>

